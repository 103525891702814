const ngModule = angular.module('ppa.filters.tenancy', []);

ngModule.filter('capitalise', function() {
    return function(input) {
      return (!!input) ? input.charAt(0).toUpperCase() + input.substr(1).toLowerCase() : '';
    };
});


ngModule.filter('filterTenancyBySegment', function(){
    return function(tenancies, segment){
        if(!tenancies) {
            return [];
        }
        return tenancies.filter(function(tenancy){
            return tenancy.segments.indexOf(segment) > -1;
        });
    };
});