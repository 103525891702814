
const ngModule = angular.module('ppa.services.rent', [
    'ppa.services.rx',
    'ppa.services.resources',
    'ppa.services.tenant',
    'ppa.services.tenancy',
    'ppa.services.utils',
    'ppa.values.base-api'
]);

ngModule.service('rentService', ['$http', '$q', '$filter', '$sce', 'rx', 'resources', 'propertyService', 'tenantService', 'tenancyService', 'utils', 'baseApi', 'ppMoment', function($http, $q, $filter, $sce, rx, resources, propertyService, tenantService, tenancyService, utils, baseApi, ppMoment) {

    var api = {};

    var RENT_ENDPOINT = '/api/rents';
    var REPORT_DOWNLOAD_ENDPOINT = '/service/download-report';
    var PAYMENT_ENDPOINT = '/api/incomingpayments';



    var PAYMENT_BY_RENT_ENDPOINT = PAYMENT_ENDPOINT + '?rent=:rentId';

    var GIPHY_CELEBRATION_ENDPOINT = 'https://api.giphy.com/v1/gifs/random?api_key=lN81nKcpSZ8m0hWifVAZn3VtnN989oXU&tag=celebration&rating=G';

    var rentReportSubject = new rx.Subject();
    var rentsForReportSubject = new rx.Subject();
    var paymentsForReportSubject = new rx.ReplaySubject();

    var _rentReportRents;
    var _rentReportPayments;

    var giphyCache;


    function sumTotalPayment(total, payment) {
        return total + payment.amount;
    }

    function populateRentsWithPayments(rents) {

        if(!rents || !rents.length) {
            return [];
        }

        var query = [{
            where: 'rent',
            value: JSON.stringify(rents.map(function(rent){
                return rent._id;
            }))
        }];


        return resources.httpGet(PAYMENT_ENDPOINT, query, null, true).then(function(payments){
            var paymentMap = utils.groupBy(payments, 'rent');
            return rents.map(function(rent){
                rent.payments = paymentMap[rent._id] || [];
                rent.totalPaid = rent.payments.reduce(sumTotalPayment, 0);
                return rent;
            });
        });
    }

    function getDateRangeQuery(dateField, startDate, endDate) {
        return [{
            where: dateField,
            value: $filter('date')(startDate, 'yyyy-MM-dd'),
            comparator: '>=',
            type: 'date'
        },
        {
            where: dateField,
            value: $filter('date')(endDate, 'yyyy-MM-dd'),
            comparator: '<=',
            type: 'date'
        }];

    }

    function getPropertyQuery(propertyId) {
        return [{
            where: 'property',
            value: propertyId
        }];
    }

    function contructQuery(dateField, startDate, endDate, property) {
        var query = [];

        if(startDate && endDate) {
            query = query.concat(getDateRangeQuery(dateField, startDate, endDate));
        }

        if(property) {
            query = query.concat(getPropertyQuery(property));
        }

        return query;
    }

    function hasMoreToPay(rent) {
        return rent.totalPaid < rent.totalAmount;
    }

    api.getRentCards = function() {

        var query = [{
            where: 'paid',
            comparator: '!=',
            value: 'true',
            type: 'boolean'
        },{
            where: 'depth',
            value: 2
        }];

        return resources.httpGet(RENT_ENDPOINT, query, null, true).then(function(rents){
            return populateRentsWithPayments(rents).then(function(rents){
                return rents.filter(hasMoreToPay);
            });
        });
    };

    api.refreshRents = function() {
        if(!_rentReportRents) {
            return;
        }

        _rentReportRents().then(function(rents){
            rentsForReportSubject.next(rents);
        });


    };

    api.refreshPayments = function() {

        if(!_rentReportPayments) {
            return;
        }

        _rentReportPayments().then(function(payments){
            paymentsForReportSubject.next(payments);
        });
    };

    api.updateRent = function(id, rent) {
        var endpoint = RENT_ENDPOINT + '/' + id;
        var payload = angular.copy(rent);

        if(!angular.isString(payload.property)) {
            payload.property = payload.property._id;
        }

        if(!angular.isString(payload.tenancy)) {
            payload.tenancy = payload.tenancy._id;
        }

        return resources.httpPut(endpoint, payload).then(function(){
            api.refreshRents();
        });
    };

    api.postRent = function(rent) {
        var endpoint = RENT_ENDPOINT;
        return resources.httpPost(endpoint, rent);
    };

    api.deleteRent = function(id) {
        var endpoint = RENT_ENDPOINT + '/' + id;
        return resources.httpDelete(endpoint);
    };

    api.postPayment = function(payment) {
        return resources.httpPost(PAYMENT_ENDPOINT, payment)
            .then(api.refreshPayments);
    };

    api.deletePayment = function(paymentId, rent) {
        var endpoint = PAYMENT_ENDPOINT + '/' + paymentId;
        var rentPayload = angular.copy(rent);
        rentPayload.paid = false;
        delete rentPayload.payments;
        return api.updateRent(rentPayload._id, rentPayload).then(function(){
            return resources.httpDelete(endpoint);
        }).then(function(){
            api.refreshPayments();
        });
    };

    api.updatePayment = function(id, payment) {
        var endpoint = PAYMENT_ENDPOINT + '/' + id;
        return resources.httpPut(endpoint, payment).then(function(){
            api.refreshPayments();
        });
    };

    api.getPaymentsForDateRange = function(startDate, endDate, property) {
        var getPaymentQuery = contructQuery.bind(null, 'datePaid', startDate, endDate, property);
        return resources.httpGet(PAYMENT_ENDPOINT, getPaymentQuery(), null, true);
    };

    api.getPaymentsTotalForDateRange = function(startDate, endDate) {
        return api.getPaymentsForDateRange(startDate, endDate).then(function(payments){
            return payments.reduce(utils.sumAmounts.bind(null, 'amount'), 0);
        });
    };

    api.constructRentReport = function(rents, payments) {

        var paymentsMap = utils.groupBy(payments, 'rent');

        var sortByDatePaid = utils.sortByDate.bind(null, 'datePaid');

        var groupedRents = rents.map(function(rent){
            rent.payments = paymentsMap[rent._id] || [];

            rent.amount = rent.payments.reduce(function(amount, payment){
                return amount + payment.amount;
            },0);

            if(rent.payments.length) {
                rent.datePaid = rent.payments.sort(sortByDatePaid)[0].datePaid;
            }

            return rent;
        })
        .filter(function(rent){
            // filter rents that have no payments
            return rent.amount;
        })
        .sort(utils.sortByDate.bind(null, 'datePaid'))
        .reduce(utils.groupByMonth.bind(null, 'datePaid'), {});

        return {
            total: payments.reduce(utils.sumAmounts.bind(null, 'amount'), 0),
            rents: groupedRents,
            paymentsMap: paymentsMap,
            payments: payments
        };
    };

    api.getRentsForReport = function(startDate, endDate, property) {

        _rentReportRents = function() {
            var getRentQuery = contructQuery.bind(null, 'dueDate', startDate, endDate, property);
            return resources.httpGet(RENT_ENDPOINT, getRentQuery(), null, true);
        };

        return _rentReportRents;

    };

    api.getPaymentsForReport = function(startDate, endDate, property) {
        _rentReportPayments = function() {
            return api.getPaymentsForDateRange(startDate, endDate, property);
        };
        return _rentReportPayments;
    };

    api.observeRentsForReport = function(startDate, endDate, property) {
        var promise = rx.Observable.fromPromise(api.getRentsForReport(startDate, endDate, property)());
        var stream = rentsForReportSubject.asObservable();
        return rx.Observable.merge(promise, stream);
    };

    api.observePaymentsForReport = function(startDate, endDate, property) {
        var promise = rx.Observable.fromPromise(api.getPaymentsForReport(startDate, endDate, property)());
        var stream = paymentsForReportSubject.asObservable();
        return rx.Observable.merge(promise, stream);
    };

    api.observeReport = function(startDate, endDate, property) {
        // Report is limited by payments but we need rents to populate property information
        // So hack is to make date range for rents all time...
        // works because we have so little data, don't tell Ramzi
        return rx.Observable.combineLatest(
            api.observeRentsForReport(new Date('1990-01-01'), new Date('2100-01-01'), property),
            api.observePaymentsForReport(startDate, endDate, property)
        );

    };

    function createTenantList(idMap, list, item) {
        var divider = list ? ' | ' : '';
        return list + divider + idMap[item].firstName + ' ' + idMap[item].lastName;
    }

    function getTenants(tenancies, rents, tenants, payment) {
        var rent = rents[payment.rent] || {};
        var tenancy = tenancies[rent.tenancy] || {};
        if(!tenancy.tenants) {
            return;
        }

        return tenancy.tenants.reduce(createTenantList.bind(this, tenants), '');
    }

    function normaliseReportDownload(buildings, units, tenancies, rents, tenants, item) {
        return {
            date_paid: ppMoment(item.datePaid).format(),
            property_name: propertyService.getPropertyName(buildings, units, item.property),
            tenants: getTenants(tenancies, rents, tenants, item),
            amount: item.amount
        };
    }

    api.getReportDownloadUrl = function(payments, startDate, endDate, buildings, units, tenancies, rents, tenants) {

       var payload = {
            data: payments.map(normaliseReportDownload.bind(this, buildings, units, tenancies, rents, tenants)),
            fileName: 'rent-report-' + startDate + '-' + endDate,
            format: 'csv'
        };

        return resources.httpPost(REPORT_DOWNLOAD_ENDPOINT, payload);

    };

    api.getCelebrationVideo = function() {

        if(!giphyCache) {
            giphyCache = $http.get(GIPHY_CELEBRATION_ENDPOINT).then(function(response){
                return response.data.data.image_mp4_url;
            });
        }

        return giphyCache;

    };

    return api;

}]);