const ngModule = angular.module('ppa.widgets.manage-tenancies', [
    'ppa.services.rx',
    'ppa.services.tenancy',
    'ppa.services.rent',
    'ppa.services.observe',
    'ppa.services.utils',
    'ppa.services.dialog',
    'ppa.services.snack',
    'ppa.filters.tenancy',
    'ppa.widgets.tenancy-card',
    'ppa.widgets.segment-search'
]);

ngModule.component('ppaManageTenancies', {
    template: require('./manage-tenancies.pug'),
    controllerAs: 'vm',
    controller: ['$rootScope', '$scope', '$q', 'rx', 'tenancyService', 'observeService', 'utils', 'dialogService', 'snackService','rentService', function($rootScope, $scope, $q, rx, tenancyService, observeService, utils, dialogService, snackService, rentService){

        var vm = this;

        // --  initial state

        vm.segment = 'open';

        function isReady() {
            return utils.isReady(
                vm.tenancies,
                vm.units,
                vm.buildings,
                vm.tenants
            );
        }

        function init() {

            vm.tenancyStream = tenancyService.observeTenancies()
            .subscribe(function(tenancies){
                vm.tenancies = tenancies.list;
                vm.tenancyAttributes = tenancies.attributes;
                vm.segmentOptions = tenancyService.getAllSegmentOptionsFromTenancies(vm.tenancies);
                vm.ready = isReady();
            });

            vm.dataStream = rx.Observable.merge(
                observeService.properties(),
                observeService.tenants()
            )
            .subscribe(function(res){
                vm.units = res.unitsMap || vm.units;
                vm.unitsList = res.units || vm.unitsList;
                vm.tenants = res.tenants || vm.tenants;
                vm.buildings = res.buildingsMap || vm.buildings;

                vm.ready = isReady();
            });
        }


        function updateTenancy(tenancy) {
            return tenancyService.updateTenancy(tenancy).then(function(){
                snackService.success('Tenancy saved');
            }, function(){
                snackService.error('An error occurred, could not save the tenancy');
            });
        }

        function closeTenancy(tenancy, newRents) {
            return $q.all(newRents.map(rentService.postRent)).then(function(){
                return tenancyService.closeTenancy(tenancy)
                    .then(function(){
                        snackService.success('Leaving date set');
                    }, function(){
                        snackService.error('An error occurred, could not close the tenancy');
                    })
                    .then(dialogService.close);
            });
        }

        // -- api

        vm.openEditTenancy = function(tenancy) {

            var scope = $rootScope.$new();

            scope = Object.assign(scope, {
                tenancy: tenancy,
                buildings: vm.buildings,
                units: vm.unitsList,
                tenants: vm.tenants,
                updateTenancy: updateTenancy,
                tenancyAttributes: vm.tenancyAttributes[tenancy._id]
            });

            var directive = '<ppa-tenancy-form tenancy="tenancy" tenancy-attributes="tenancyAttributes" buildings="buildings" units="units" tenants="tenants" disable-property-select="true" on-submit="updateTenancy(tenancy)"></ppa-tenancy-form>';

            var options = {
                classNames: 'ppa-tenancy-add',
                contents: directive,
                scope: scope
            };

            vm.editingTenancy = tenancy._id;

            return dialogService.show(options).then(function(){
                vm.editingTenancy = null;
            });

        };


        vm.openCloseTenancyForm = function(tenancy) {

            var scope = $rootScope.$new();

            scope = Object.assign(scope, {
                tenancy: tenancy,
                closeTenancy: closeTenancy,
                tenancyAttributes: vm.tenancyAttributes[tenancy._id]
            });

            var directive = '<ppa-close-tenancy tenancy="tenancy" on-submit="closeTenancy(tenancy, newRents)" tenancy-attributes="tenancyAttributes"></ppa-close-tenancy>';

            var options = {
                classNames: 'ppa-tenancy-close',
                contents: directive,
                scope: scope
            };

            vm.closingTenancy = tenancy._id;

            return dialogService.show(options, true).then(function(){
                vm.closingTenancy = null;
            });
        };

        vm.controlTenancyCardMenu = function(tenancy) {
            if(vm.openTenancyMenu === tenancy._id) {
                vm.openTenancyMenu = null;
            } else {
                vm.openTenancyMenu = tenancy._id;
            }
        };

        // -- scope bindings

        // -- lifecycle

        vm.$onDestroy = function() {
            utils.unsubscribe(vm.tenancyStream);
            utils.unsubscribe(vm.dataStream);
        };

        // -- main

        vm.$onInit = function() {
            init();
        };





    }]
});