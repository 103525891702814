const ngModule = angular.module('ppa.widgets.expense-report', [
    'ppa.services.rx',
    'ppa.services.observe',
    'ppa.services.expense',
    'ppa.services.date',
    'ppa.services.dialog',
    'ppa.services.utils',
    'ppa.directives.bg-colour-content',
    'ppa.widgets.date-range'
]);

ngModule.component('ppaExpenseReport', {
    template: require('./expense-report.pug'),
    bindings: {},
    controllerAs: 'vm',
    controller: ['$rootScope', '$scope', '$timeout', 'rx', 'observeService', 'expenseService', 'dateService', 'dialogService', 'utils',
        function($rootScope, $scope, $timeout, rx, observeService, expenseService, dateService, dialogService, utils) {

            var vm = this;

            // -- initial state

            // -- util functions

            function isReady() {
                return utils.isReady(
                    vm.expenses,
                    vm.categories,
                    vm.buildings,
                    vm.units,
                    vm.clients
                );
            }

            function openEditor(expense) {
                vm.activeExpenseId = expense._id;
                var scope = $rootScope.$new();
                scope.expense = angular.copy(expense);
                var directive = '<ppa-expense-edit expense="expense"></ppa-expense-edit>';
                var options = {
                    scope: scope,
                    contents: directive
                };

                return dialogService.show(options).then(function(){
                    vm.activeExpenseId = null;
                });
            }

            function unsubscribeDownload() {
                utils.unsubscribe(vm.downloadStream);
            }

            function unsubscribeReport() {
                utils.unsubscribe(vm.expenseStream);
            }


            function observeExpenses() {

                unsubscribeReport();

                vm.expenseObservable = expenseService.observeExpensesForDateRange(
                    vm.dateRange.fromDate,
                    vm.dateRange.toDate,
                    vm.filteredProperty,
                    vm.filteredCategory
                );

                return vm.expenseObservable.subscribe(function(expenses){
                    vm.expenses = expenses.sort(utils.sortByDate.bind(this, 'date')).reduce(utils.groupByMonth.bind(null, 'date'), {});
                    vm.total = expenses.reduce(utils.sumAmounts.bind(null, 'amount'), 0);
                    vm.ready = isReady();
                });
            }

            function setupReportData() {
                return rx.Observable.merge(
                    observeService.properties(),
                    observeService.clientsAndCategories()
                )
                .subscribe(function(data) {
                    vm.categories = data.categoriesMap || vm.categories;
                    vm.categoriesList = data.categories || vm.categoriesList;
                    vm.buildings = data.buildingsMap || vm.buildings;
                    vm.units = data.unitsMap || vm.units;
                    vm.unitsList = data.units || vm.unitsList;
                    vm.clients = data.clientsMap || vm.clients;
                    vm.ready = isReady();
                });

            }

            // -- api

            vm.clickRow = function(expense) {
                openEditor(expense);
            };

            vm.sumTotal = function(total, item) {
                return item.amount + total;
            };

            vm.clearPropertyFilter = function() {
                vm.filteredProperty = null;
                observeExpenses();
            };

            vm.clearCategoryFilter = function() {
                vm.filteredCategory = null;
                observeExpenses();
            };

            vm.downloadReport = function() {
                unsubscribeDownload();

                vm.downloadError = null;

                vm.downloadingReport = true;

                vm.downloadStream = vm.expenseObservable.pipe(rx.operators.take(1)).subscribe(function(expenses){
                    expenseService.getDownloadUrl(
                        expenses,
                        vm.dateRange.fromDate,
                        vm.dateRange.toDate,
                        vm.buildings,
                        vm.units,
                        vm.categories,
                        vm.clients
                    ).then(function(res){
                        vm.fileDownloadLink = res.url;

                        return $timeout(function(){
                            document.getElementById('expense-report-file-download').click();
                        }, 1000);

                    }, function(err){
                        vm.downloadError = true;
                    }).then(function(){
                        vm.downloadingReport = false;
                    });
                });
            };

            vm.updateReport = function(){
                $timeout(function(){
                    observeExpenses();
                });
            };

            // -- scope bindings

            $scope.$on("dateChanged", function(event, range) {
                vm.dateRange = range;
                vm.ready = false;
                observeExpenses();
            });

            // -- main

            vm.$onDestroy = function() {
                utils.unsubscribe(vm.dataStream);
                unsubscribeReport();
                unsubscribeDownload();
            };

            vm.dataStream = setupReportData();

            dateService.getDateRange().then(function(dateRange){
                vm.dateRange = dateRange;
                vm.expenseStream = observeExpenses();
            });

        }]

});
